import React from "react";
import '../../styles/loading.css'

const LoadingCustom: React.FC = () => {
    return (
        <div className="parent">
            <div className="loader"></div>
        </div>

    );
};

export default LoadingCustom;
