import { Route, Routes } from 'react-router-dom';
import PromotionSheducled from '../components/Promotion/PromotionScheduled.tsx';
import AdminLayout from '../layouts/Admin';
import UserLayout from '../layouts/Customer/index.tsx';
import ManagerColor from '../pages/Admin/Attributes/color/colorIndex.tsx';
import ManagerMaterial from '../pages/Admin/Attributes/material/materialIndex.tsx';
import ManagerSize from '../pages/Admin/Attributes/size/sizeIndex.tsx';
import ChatPage from '../pages/Admin/Chat/index.tsx';
import ManagerBrand from '../pages/Admin/Product/brand/ManagerBrand.tsx';
import ManagerCategory from '../pages/Admin/Product/Category/ManagerCategory.tsx';
import ProductManager from '../pages/Admin/Product/ManageProduct';
import ManaggerOrigin from '../pages/Admin/Product/origin/ManagerOrigin.tsx';
import ManageProductDetail from '../pages/Admin/Product/product_detail/ManageProductDetail.tsx';
import ManagerPromotion from '../pages/Admin/Product/Promotion/PromotionManager.tsx';
import { ManagerRole, ManagerUser } from "../pages/Admin/User";
import AuthenticateFacebook from '../pages/Admin/User/AuthenticateFacebook';
import AuthenticateGoogle from '../pages/Admin/User/AuthenticateGoogle';
import Login from '../pages/Admin/User/Login';
import CartPage from '../pages/Customer/cart/page.tsx';
import ProductFilterPage from '../pages/Customer/filter/page.tsx';
import HomePage from '../pages/Customer/home/page.tsx';
import ProductDetailPage from '../pages/Customer/product-detail/page.tsx';
import ChangePasswordPage from '../pages/Customer/user-info/ChangePasswordPage.tsx';
import UserInfoLayout from '../pages/Customer/user-info/index.tsx';
import UserInfoPage from '../pages/Customer/user-info/UserInfoPage.tsx';
import ManagerDiscount from '../pages/Admin/Discount/ManagerDiscount.tsx';
import ManagerVoucher from '../pages/Admin/Voucher/ManagerVoucher.tsx';
import DiscountDetailModal from '../components/Discount/DiscountDetailModal.tsx';
import AddDiscount from '../components/Discount/AddDiscount.tsx';
import UpdateDiscount from '../components/Discount/UpdateDiscount.tsx';
import ManagerOrder from '../pages/Admin/Order/ManagerOrder.tsx';
import ForbiddenPage from "../pages/Error/ForbiddenPage.tsx";
import CheckoutPage from '../pages/Customer/checkout/page.tsx';
import SellingAtStore from '../pages/Admin/Store/SellingAtStore.tsx';
import ConfirmVnPayCheckout from '../pages/Customer/checkout/confirm/ConfirmVnPayCheckout.tsx';
import Statistics from '../pages/Admin/Statistics/Statistics.tsx';
import OrderDetailPage from '../components/Order/OrderDetailPage.tsx';
import CustomerOrder from '../pages/Customer/customer-order/CustomerOrder.tsx';
import OrderStatusCustomer from '../pages/Customer/customer-order/OrderDetailView.tsx';
import PaymentQRComponent from "../pages/Customer/checkout/PaymentQRComponent";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/authenticate" element={<AuthenticateGoogle />} />
            <Route path="/authenticate-fb" element={<AuthenticateFacebook />} />

            <Route element={<UserLayout />}>
                <Route path='/' element={<HomePage />} />
                <Route path='/product/:id' element={<ProductDetailPage />} />
                <Route path='/filter' element={<ProductFilterPage />} />
                <Route path='/cart' element={<CartPage />} />
                <Route path='/checkout' element={<CheckoutPage />} />
                <Route path="/checkout/status" element={<ConfirmVnPayCheckout />} />
                <Route path="/checkout/qr" element={<PaymentQRComponent />} />
                <Route element={<UserInfoLayout />}>
                    <Route path="/user-info" element={<UserInfoPage />} />
                    <Route path="/change-password" element={<ChangePasswordPage />} />
                </Route>
                <Route path="/customer-order" element={<CustomerOrder/>}/>
                <Route path="/customer-order/:id" element={<OrderStatusCustomer/>}/>

            </Route>
            <Route element={<AdminLayout />}>
                <Route path="/admin/statistics" element={<Statistics />} />
                <Route path="/admin/store" element={<SellingAtStore />} />
                <Route path="/admin/product/" element={<ProductManager />} />
                <Route path="/admin/product-detail/" element={<ManageProductDetail />} />
                <Route path="/admin/user/role" element={<ManagerRole />} />
                <Route path="/admin/brand" element={<ManagerBrand />} />
                <Route path="/admin/origin" element={<ManaggerOrigin />} />
                <Route path="/admin/color" element={<ManagerColor />} />
                <Route path="/admin/size" element={<ManagerSize />} />
                <Route path="/admin/material" element={<ManagerMaterial />} />
                <Route path="/admin/chat" element={<ChatPage />} />
                <Route path="/admin/user" element={<ManagerUser />} />
                <Route path="/admin/discount" element={<ManagerDiscount />} />
                <Route path="/admin/voucher" element={<ManagerVoucher />} />
                <Route path="/admin/category" element={<ManagerCategory />} />
                <Route path="/admin/promotion" element={<ManagerPromotion />} />
                <Route path="/admin/promotion/scheduled/:id" element={<PromotionSheducled />} />
                <Route path="/admin/discount/:discountId" element={<DiscountDetailModal />} />
                <Route path="/admin/discount/edit/:discountId" element={<UpdateDiscount />} />
                <Route path="/admin/discount/add" element={<AddDiscount />} />
                <Route path="/admin/order" element={<ManagerOrder />} />
                <Route path="/admin/order/:orderId" element={<OrderDetailPage />} />
                <Route element={<UserInfoLayout page='admin' />}>
                    <Route path="/admin/user-info" element={<UserInfoPage />} />
                    <Route path="/admin/change-password" element={<ChangePasswordPage />} />
                </Route>
            </Route>


            {/* Route xử lý lỗi */}
            <Route path="/403" element={<ForbiddenPage />} />

        </Routes>
    );
};

export default AppRoutes;